<template>
  <div id="chart-cadastros" class="chart-container"></div>
</template>

<script>
  import Highcharts from 'highcharts'
  import {getEstatisticasCadastrosArrematantes} from '../../../../domain/estatisticas/services'

  const MONTHS = {
    1: { fullName: 'Janeiro', abbreviated: 'Jan'},
    2: { fullName: 'Fevereiro', abbreviated: 'Fev'},
    3: { fullName: 'Março', abbreviated: 'Mar'},
    4: { fullName: 'Abril', abbreviated: 'Abr'},
    5: { fullName: 'Maio', abbreviated: 'Maio'},
    6: { fullName: 'Junho', abbreviated: 'Jun'},
    7: { fullName: 'Julho', abbreviated: 'Jul'},
    8: { fullName: 'Agosto', abbreviated: 'Ago'},
    9: { fullName: 'Setembro', abbreviated: 'Set'},
    10: { fullName: 'Outubro', abbreviated: 'Out'},
    11: { fullName: 'Novembro', abbreviated: 'Nov'},
    12: { fullName: 'Dezembro', abbreviated: 'Dez'},
  }

  const CHART_DATA_MODEL = {
    categories: [
      'Jan/2020',
      'Fev/2020',
      'Mar/2020',
      'Abr/2020',
      'Maio/2020',
      'Jun/2020',
      'Jul/2020',
      'Ago/2020',
      'Set/2020',
      'Out/2020',
      'Nov/2020',
      'Dez/2020'
    ],
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  }

  export default {
    name: 'Cadastros',
    data () {
      return {
        chartData: null,
        CHART_DATA_MODEL
      }
    },
    created () {
      this.load()
    },
    mounted () {
      Highcharts.setOptions({
        lang: {
          thousandsSep: '.'
        }
      })
      this.createChart()
    },
    watch: {
      'chartData': function (data) {
        this.CHART_DATA_MODEL.data = data.map(item => +item.count)
        this.CHART_DATA_MODEL.categories = data.map(item => item.month)
        this.createChart()
      }
    },
    methods: {
      load () {
        getEstatisticasCadastrosArrematantes()
                .then(response => {
                  const {dados} = response.data
                  this.chartData = (dados || []).map(item => {
                    const {count, month, year} = item
                    return {
                      month: `${MONTHS[+month].abbreviated}/${year}`,
                      count
                    }
                  })
                  })
      },
      createChart () {
        Highcharts.chart('chart-cadastros', {
          title: {
            text: 'Estatísticas de Cadastro de Arrematantes'
          },
          subtitle: {
            text: 'Fonte: ERP Suporte Leilões'
          },
          legend: {
            enabled: false
          },
          xAxis: {
            categories: this.CHART_DATA_MODEL.categories
          },
          yAxis: {
            title: {
              text: 'Nº de Cadastros'
            }
          },
          series: [{
            data: this.CHART_DATA_MODEL.data
          }]
        })
      }
    }
  }
</script>
