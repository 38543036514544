export default {
  mounted () {
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 400)
  },
  beforeDestroy () {
    this.erplayout.erpheader.setMenuModuleToggle(false)
  }
}
