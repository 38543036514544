<template>
  <div id="container" class="chart-container"></div>
</template>

<script>
import Highcharts from 'highcharts'
import Annotation from 'highcharts/modules/annotations'
Annotation(Highcharts)

export default {
  name: 'Acessos',
  mounted () {
    Highcharts.setOptions({
      lang: {
        thousandsSep: '.'
      }
    })

    Highcharts.chart('container', {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Estatísticas de Acesso de Arrematantes'
      },
      subtitle: {
        text: 'Fonte: ERP Suporte Leilões'
      },
      xAxis: {
        categories: [
          'Jan',
          'Fev',
          'Mar',
          'Apr',
          'Maio',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Rainfall (mm)'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          // pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        name: 'Acessos',
        data: [499, 715, 1064, 1292, 1440, 1760, 1356, 1485, 2164, 1941, 956, 544]

      }/* , {
        name: 'New York',
        data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]

      }, {
        name: 'London',
        data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2]

      }, {
        name: 'Berlin',
        data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8, 51.1]

      } */]
    });
  }
}
</script>

<style scoped>

</style>
