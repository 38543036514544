<template>
  <div id="chart-origem-acesso" class="chart-container"></div>
</template>

<script>
import Highcharts from 'highcharts'

export default {
  name: 'OrigemAcesso',
  mounted () {
    setTimeout(() => {
      this.load()
    }, 300)
  },
  methods: {
    load () {
      Highcharts.chart('chart-origem-acesso', {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false
        },
        title: {
          text: 'Origem dos <br>acessos<br>',
          align: 'center',
          verticalAlign: 'middle',
          y: 60
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            size: '100%'
          }
        },
        series: [{
          type: 'pie',
          name: 'Plataforma',
          innerSize: '50%',
          data: [
            ['Web Mobile', 58.9],
            ['Web Browser', 13.29],
            ['App Android', 13],
            ['App iOS', 3.78],
            {
              name: 'Other',
              y: 7.61,
              dataLabels: {
                enabled: false
              }
            }
          ]
        }]
      });
    }
  }
}
</script>

<style scoped>

</style>
