<template>
  <div id="chart-aprovacao" class="chart-container"></div>
</template>

<script>
import Highcharts from 'highcharts'
import {STATUS_APROVADO, STATUS_BLOQUEADO, STATUS_EM_ANALISE, STATUS_PENDENTE, STATUS_REPROVADO} from '../../../../domain/arrematante/helpers/Status'
import {getCountStatusCadastro} from '@/domain/arrematante/services'

const CHART_DATA_MODEL = [
  { name: 'Sem documentação', y: 61.41, total1: 1300, arrematante_status: STATUS_PENDENTE },
  { name: 'Aprovados', y: 11.84, total1: 232, arrematante_status: STATUS_APROVADO },
  { name: 'Não aprovados', y: 10.85, total1: 211, arrematante_status: STATUS_REPROVADO },
  { name: 'Em análise', y: 4.67, total1: 23, arrematante_status: STATUS_EM_ANALISE },
  { name: 'Bloqueados', y: 7.05, total1: 78, arrematante_status: STATUS_BLOQUEADO }
]

export default {
  name: 'Aprovacao',
  data () {
    return {
      originalColors: null,
      statuses: [],
      arrematanteStatus: {
        [STATUS_APROVADO]: STATUS_APROVADO,
        [STATUS_BLOQUEADO]: STATUS_BLOQUEADO,
        [STATUS_EM_ANALISE]: STATUS_EM_ANALISE,
        [STATUS_PENDENTE]: STATUS_PENDENTE,
        [STATUS_REPROVADO]: STATUS_REPROVADO
      },
      CHART_DATA_MODEL,
      chart: null
    }
  },
  created () {
    this.load()
  },
  mounted () {
    const radialize = () => Highcharts.map(Highcharts.getOptions().colors, function (color) {
      return {
        radialGradient: {
          cx: 0.5,
          cy: 0.3,
          r: 0.7
        },
        stops: [
          [0, color],
          [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
        ]
      };
    })

    // Radialize the colors
    /* Highcharts.setOptions({
      colors: radialize()
    }); */

    this.originalColors = Highcharts.getOptions().colors
    Highcharts.getOptions().colors = radialize()
  },
  beforeDestroy () {
    Highcharts.getOptions().colors = this.originalColors
  },
  computed: {
    total () {
      const tmp = [...this.statuses]
      delete tmp[STATUS_APROVADO]
      return tmp.reduce((acc, status) => acc + status.count, 0)
    }
  },
  watch: {
    'statuses': function(statuses) {
      for (const value of statuses) {
        this.arrematanteStatus[+value.status] = value.count
      }
      for (const value of this.CHART_DATA_MODEL) {
        value.total1 = this.arrematanteStatus[value.arrematante_status]
        value.total1 = this.$options.filters.number(value.total1)
        value.y = (this.arrematanteStatus[value.arrematante_status] * 100) / this.total
      }
      this.createChart()
    }
  },
  methods: {
    load () {
      getCountStatusCadastro()
              .then(response => {
                this.statuses = response.data
              })
    },
    createChart () {
      this.chart = Highcharts.chart('chart-aprovacao', {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: 'Aprovação de cadastros'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b> ({point.total1})'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.2f} %',
              connectorColor: 'silver'
            }
          }
        },
        series: [{
          name: 'Porcentagem',
          data: this.CHART_DATA_MODEL
        }]
      });
    }
  }
}
</script>
